export const NO_REGION_SELECTED = 'Region not selected'
export const NO_RATE_SELECTED = 'Rate not selected'

export const meters = {
  namespaced: true,
  state: {},
  getters: {
    
  },
  actions: {
    
  },
  mutations: {
    
  },
};
