import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../plugins/firebase'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login'),
        meta: {
            public: true,
            title: 'Log In',
        },
    },
    {
        path: '/select-project',
        name: 'selectProject',
        component: () => import('../views/SelectProject'),
        meta: {
            public: false,
            title: 'Select Project',
        },
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home'),
        meta: {
            public: false,
            title: 'Home',
        },
    },
    {
        path: '/control',
        name: 'control',
        component: () => import('../views/Control'),
        meta: {
            public: false,
            title: 'Control Center',
        },
    },
    {
        path: '/meters',
        component: () => import('@/views/MeterPage'),
        children: [
            {
                path: '/',
                name: 'MeterMenu',
                component: () => import('@/components/meter/Menu'),
                meta: {
                    public: false,
                    title: 'Meter Menu',
                },
            },
            {
                path: ':id',
                name: 'Meter',
                component: () => import('@/components/meter/Dashboard'),
                meta: {
                    public: false,
                    title: 'Meter View',
                },
            },
        ],
    },
    {
        path: '/schedules/',
        name: 'schedules',
        component: () => import('@/views/Schedule'),
        meta: {
            public: false,
            title: 'Schedules',
        },
    },
    {
        path: '/layout/',
        name: 'ControlLayouts',
        component: () => import('@/views/ControlLayouts'),
        meta: {
            public: false,
            title: 'Layouts Control',
        },
    },
    {
        path: '/control/layout/:id',
        name: 'LayoutZones',
        component: () => import('@/views/LayoutZones'),
        meta: {
            public: false,
            title: 'Layout Zones',
        },
    },
    {
        path: '/layout/metering',
        name: 'LayoutMeter',
        component: () => import('@/views/LayoutMetering'),
        meta: {
            public: false,
            title: 'Layout Meter',
        },
    },
    {
        path: '/layout/one-line',
        name: 'LayoutOneLineDiagram',
        component: () => import('@/views/LayoutOneLine'),
        meta: {
            public: false,
            title: 'Layout Meter',
        },
    },
    {
        path: '/groups',
        component: () => import('@/views/GroupPage'),
        children: [
            {
                path: '',
                name: 'GroupMenu',
                component: () => import('@/components/group/GroupMenu'),
                meta: {
                    public: false,
                    title: 'Group Menu',
                },
            },
            {
                path: '/groups/:id',
                name: 'Group',
                component: () => import('@/components/group/GroupSelected'),
                meta: {
                    public: false,
                    title: 'Group View',
                },
            },
        ],
    },
    {
        path: '/logger',
        name: 'Logger',
        component: () => import('@/views/Logger'),
        meta: {
            public: false,
            title: 'Log Center',
        },
    },
    {
        path: '/project',
        name: 'ProjectInfo',
        component: () => import('@/views/ProjectInfo'),
        meta: {
            public: false,
            title: 'Project Info',
        },
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

router.beforeEach((to, from, next) => {
    store.dispatch('alert/clear', null, { root: true })

    const { currentUser } = auth
    const isPublic = to.meta.public

    if (!isPublic && !currentUser) {
        return next('/login')
    }

    if (isPublic && currentUser) {
        return next('/')
    }

    next()
})

export default router
