import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/devices';

const get = () => apiWithProject.get(`${END_POINT}`);

const getNode = (node) => apiWithProject.get(`${END_POINT}/${node}`);

const post = (node, params) => apiWithProject.post(`${END_POINT}/${node}`, {
  function: params.function,
  class: params.class,
  displayName: params.displayName,
});

const getAllAnomalies = () => apiWithProject.get(`${END_POINT}/anomalies`);

const getNodeAnomalies = (node) => apiWithProject.get(`${END_POINT}/anomalies/${node}`);

const getNodeAnomaliesBewteenDates = (dates) => 
  apiWithProject.get(`${END_POINT}/anomalies/dates/?since=${dates.start}&until=${dates.end}`);

const deletion = (node) => apiWithProject.delete(`${END_POINT}/${node}`);

const Device = { get, getNode, post, deletion, getNodeAnomalies, getAllAnomalies, getNodeAnomaliesBewteenDates };

export default Device;
