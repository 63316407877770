import { apiWithProject } from './greenetworkAPI';

const END_POINT = '/devices';

const NOT_FOUND = null

const addDevice = (id, displayName) => apiWithProject.post(`${END_POINT}/${id}`, {
  class: 'Meter',
  displayName,
  function: 'Basic',
});

const updateDisplayName = (id, displayName) => apiWithProject.put(`${END_POINT}/${id}`, {
    function: 'Basic',
    class: 'Meter',
    data: {
      assetId: id,
      displayName,
    },
  },
);

const updateRateInfo = (id, rateType, rateRegion) => apiWithProject.put(`${END_POINT}/${id}`, {
    function: 'RateParameters',
    class: 'Meter',
    data: {
      rateRegion,
      rateType
    }
  },
);

const deletion = (id) => apiWithProject.delete(`${END_POINT}/${id}`);

const functionData = (id) => apiWithProject.get(`${END_POINT}/${id}/functions/ElectricMeter`);

const getLastRead = (id) => apiWithProject.get(`${END_POINT}/${id}`)
                            .then((data) => { return data })
                            .catch(() => { return NOT_FOUND });

const reqData = (id, start, end) => {
  return apiWithProject.get(`${END_POINT}/${id}/functions/ElectricMeter/measurements?since=${start}&until=${end}`);
}

const summaryData = (id, type, requestBody, timezone) => {
  return apiWithProject.get(`${END_POINT}/${id}/functions/ElectricMeter/measurements/summary/${type}?${requestBody}&timezone=${timezone}`);
}
const multiSummaryData = async (meters, type, milis, timezone) => {
  let metersGeneralData = []
  for (let index = 0; index < meters.length; index++) {
      const meter = meters[index];
      let meterData = await apiWithProject.get(`${END_POINT}/${meter}/functions/ElectricMeter/measurements/summary/${type}?start=${milis}&timezone=${timezone}`)
      metersGeneralData.push(meterData)
      if (index === meters.length-1) {
          return metersGeneralData
      }
  }
}

const MeterGreen = {
  addDevice, updateDisplayName, updateRateInfo, functionData, getLastRead, reqData, summaryData, deletion, multiSummaryData
};

export default MeterGreen;
